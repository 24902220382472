<template>
  <div id="solutions_financial-services">
    <solutions-header
      class="financial-services-header"
      :title="$t('solutions.financialServices.title')"
      :subtitle="$t('solutions.financialServices.subtitle')"
    />
    <solutions-content
      :problem-statement="$t('solutions.financialServices.problem.statement')"
      :problem-description="$t('solutions.financialServices.problem.description')"
      :solution="$t('solutions.financialServices.solution')"
      :pipeline="require('@/assets/images/icons/solutions/finance/pipeline.svg')"
      :section-img="require('@/assets/images/icons/solutions/finance/statement.svg')"
    />
  </div>
</template>

<script>
import SolutionsHeader from '@/views/pages/Solutions/components/SolutionsHeader/Index.vue';
import SolutionsContent from '@/views/pages/Solutions/components/SolutionsContent/Index.vue';

export default {
  name: 'FinancialServices',
  components: {
    SolutionsHeader,
    SolutionsContent,
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep {
    .financial-services-header {
      .content {
        background: url('~@/assets/images/background/solutions-banner-financial.svg') 90% 60% no-repeat,
                    url('~@/assets/images/background/header-corner-bottom.svg') right 72% no-repeat;
      }
    }
  }
</style>
