<template>
  <div :class="{ 'divider': true, [variant]: variant }" />
</template>

<script>
export default {
  name: 'Divider',
  props: {
    variant: {
      type: String,
      default: 'default',
      validator: (value) => [
        'default',
        'healthcare-banner',
        'education-banner',
        'services_nlp_intro-banner',
        'services_vca_intro-banner',
        'services_aiu_intro-banner',
        'services_asa_intro-banner',
        'services_nlp_intro-content',
        'services_vca_intro-content',
        'services_aiu_intro-content',
        'services_asa_intro-content',
      ].indexOf(value) !== -1,
    },
  },
};
</script>

<style lang="scss" scoped>
.divider {
  display: block;
  content: '';
  width: 1.64rem;
  height: 0.04rem;

  &.default {
    background: linear-gradient(89.96deg, #3A71FF 0.26%, rgba(58, 113, 255, 0) 106.07%);
  }

  &.education-banner {
    background: linear-gradient(89.99deg, #A034F5 0.07%, rgba(255, 255, 255, 0) 85.12%);
  }

  &.services_nlp_intro-banner {
    background: linear-gradient(89.96deg, #FC9C0D 0.26%, rgba(255, 255, 255, 0) 106.07%);
  }

  &.services_vca_intro-banner {
    background: linear-gradient(89.96deg, #D9CAFF 0.26%, rgba(255, 255, 255, 0) 106.07%);
  }

  &.services_aiu_intro-banner {
    background: linear-gradient(89.96deg, #3A71FF 0.26%, rgba(255, 255, 255, 0) 106.07%);
  }

  &.services_asa_intro-banner {
    background: linear-gradient(89.96deg, #14D4B2 0.26%, rgba(255, 255, 255, 0) 106.07%);
  }

  &.services_nlp_intro-content {
    background: linear-gradient(89.96deg, #FC9C0D 0.26%, rgba(255, 255, 255, 0) 106.07%);
  }

  &.services_vca_intro-content {
    background: linear-gradient(89.96deg, #521FD1 0.26%, rgba(255, 255, 255, 0) 106.07%);
  }

  &.services_aiu_intro-content {
    background: linear-gradient(89.96deg, #3A71FF 0.26%, rgba(255, 255, 255, 0) 106.07%);
  }

  &.services_asa_intro-content {
    background: linear-gradient(89.96deg, #13C7A7 0.26%, rgba(255, 255, 255, 0) 106.07%);
  }
}
</style>
