<template>
  <section class="solution content">
    <img
      class="content__img"
      :src="$props.sectionImg"
      :alt="$props.problemStatement"
    >
    <div class="problem">
      <h5 class="content__title">
        <strong>{{ $t('solutions.problem') }}</strong> <span>{{ $t('solutions.statement') }}</span>
      </h5>
      <p class="content__description-primary">
        {{ $props.problemStatement }}
      </p>
      <!--eslint-disable-next-line-->
      <div class="content__description-secondary" v-html="$props.problemDescription" />
    </div>

    <div class="solution">
      <h5 class="content__title">
        <strong>{{ $t('solutions.solution') }}</strong> <span>{{ $t('solutions.statement') }}</span>
      </h5>
      <!--eslint-disable-next-line-->
      <div class="content__description-secondary" v-html="$props.solution" />
    </div>

    <div class="pipeline">
      <h5 class="content__title flex">
        <strong>{{ $t(`solutions['AI services']`) }}</strong>
        <span class="content__title--thin">{{ $t('solutions.pipeline') }}</span>
        <div class="pipeline__legend">
          <img src="~@/assets/images/icons/solutions/pipeline-legend.svg">
        </div>
      </h5>

      <div class="pipeline__diagram flex flex-center">
        <img
          :src="$props.pipeline"
          :alt="$props.problemStatement"
        >
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'SolutionContent',
  components: {
  },
  props: {
    problemStatement: {
      type: String,
      required: true,
    },
    problemDescription: {
      type: String,
      required: false,
      default: '',
    },
    solution: {
      type: String,
      required: true,
    },
    pipeline: {
      type: String,
      required: true,
    },
    sectionImg: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .content {
    display: flex;
    flex-direction: column;
    padding: 0.62rem 0.86rem 0.8rem;
    position: relative;
    z-index: 0;

    &__img {
      position: absolute;
      top: 0;
      right: 0;
      max-width: 50%;
      max-height: 5.8rem;
      z-index: -1;

      @include tablet-and-less {
        max-width: 75%;
        opacity: 0.6;
      }
    }

    &__title {
      font-size: 0.28rem;
      line-height: 0.34rem;
      margin-bottom: 0.2rem;
      text-transform: capitalize;
      align-items: center;

      strong {
        font-weight: 800;
      }
    }

    &__description{
      &-primary {
        font-weight: 700;
        font-size: .14rem;
        line-height: .21rem;
        margin-bottom: .2rem;
      }

      &-secondary {
        font-weight: 400;
        font-size: .14rem;
        color: $text-tertiary;
        line-height: .21rem;

      ::v-deep {
        ol {
          padding-left: .16rem;
          list-style: decimal;
        }

        strong {
          font-weight: 700;
        }
      }
      }
    }
  }

  .problem {
    width: 60%;

    @include tablet-and-less {
      width: 100%;
    }
  }

  .solution {
    .content__description-secondary {
      line-height: .22rem;
    }
  }

  .pipeline {
    .content__title {
      flex-wrap: wrap;
      .content__title--thin {
        margin-left: .07rem;
      }
    }
    &__diagram {
      padding-top: .22rem;
    }
    &__legend {
      display: inline-flex;
      margin-left: auto;

      @include tablet-and-less {
        margin-top: .1rem;
        max-width: 60%;
      }
    }
  }

  .solution, .pipeline {
    margin-top: .36rem;
  }
</style>
