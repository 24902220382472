<template>
  <header :class="{ header: true, service: isServicePage }">
    <div class="banner-slot">
      <slot name="banner" />
    </div>
    <vue-particles
      v-if="particleOptions"
      v-bind="particleOptions"
    />
    <div class="content">
      <divider :variant="dividerVariant" />
      <div class="title">
        {{ $props.title }}
      </div>
      <div class="subtitle">
        {{ $props.subtitle }}
      </div>
      <login-button :variant="loginVariant" />
    </div>
  </header>
</template>

<script>
import Divider from '@/components/Divider/Index.vue';
import LoginButton from '@/views/components/LoginButton/Index.vue';

export default {
  name: 'SolutionsHeader',
  components: { Divider, LoginButton },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    particleOptions: {
      type: Object,
      default: null,
    },
    dividerVariant: {
      type: String,
      default: 'default',
    },
    loginVariant: {
      type: String,
      default: 'header',
    },
    isServicePage: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
  .header {
    position: relative;
    background: url('~@/assets/images/background/header-corner-top.svg') top left no-repeat, $background-header;
    color: $text-dark-bg;

    .banner-slot {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0.7rem;
      width: 100%;
      height: 100%;

      :first-child {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    &.service {
      .title {
        font-size: 0.42rem;
      }

      .content{
        padding-top: 2.3rem
      }
    }

    #particles-js {
      position: absolute;
      z-index: -2;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 2.45rem 0.87rem 1.78rem;

      background-size: auto 90%;
      background-position: right center;
      background-repeat: no-repeat;

      @include tablet-and-less {
        padding: 1.45rem 0.4rem .78rem;
      }
    }

    .title {
      @include typography-title;
      margin-top: 0.2rem;
      margin-bottom: 0.16rem;
    }

    .subtitle {
      @include typography-subtitle;
      width: 53%;
      margin-bottom: 0.48rem;

      @include tablet-and-less {
        width: 100%;
      }
    }
  }
</style>
